@import '../../../styles/variables.css';

.container {
  @media (--tablet-portrait-up) {
    display: flex;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @mixin font-family-title;
    @mixin font-bolder;
    font-size: 36px;
    margin-bottom: var(--space-sm);
  }

  p {
    margin-bottom: var(--space-sm);

    @media (--tablet-portrait-up) {
      margin-bottom: var(--space-md);
    }
  }

  ul li {
    position: relative;
    padding-left: 20px;
    font-size: 12px;

    @media (--tablet-portrait-up) {
      font-size: 14px;
    }

    &::before {
      content: '\2022';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.menu {
  margin-bottom: var(--space-lg);

  @media (--tablet-portrait-up) {
    position: sticky;
    top: var(--header-height);
    width: 25%;
    align-self: flex-start;
    min-height: calc(100vh - 100px);
    margin-right: 50px;
    padding: 20px 40px 40px 0;
    border-right: 1px solid #ddd;
  }

  @media (--tablet-landscape-up) {
    padding: 40px;
    min-height: calc(100vh - 120px);
  }
}

.menuLink,
.menuSublink {
  @mixin btn-reset;
  @mixin font-family-title;
  @mixin font-bolder;
  margin: 0 0 var(--space-sm);
  font-size: 16px;

  @media (--tablet-portrait-up) {
    margin-bottom: var(--space-md);
  }
}

.menuLink {
  /* @mixin font-medium; */
}

.content {
  @media (--tablet-portrait-up) {
    width: 75%;
    padding-top: 10px;
    padding-right: 20px;
  }

  @media (--tablet-landscape-up) {
    padding-top: 30px;
  }
}
