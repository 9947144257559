@import '../../../styles/variables.css';

.container {
}

.containerSlice {
}

.media {
}

body .mediaPortrait {
  width: 50%;
  margin: 0 auto;

  picture,
  img {
    object-fit: contain;
  }
}
