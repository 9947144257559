@import '../../../styles/variables.css';

.container {
  padding-bottom: 40px;

  @media (--tablet-landscape-up) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* padding: 0 var(--container-padding) 100px; */
    padding-bottom: 100px;
  }
}

.item {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 40px;

    @media (--tablet-landscape-up) {
      margin-bottom: 0;
    }
  }
}

.half {
  @media (--tablet-landscape-up) {
    width: 50%;
  }
}

body .media {
  height: 100%;

  > *,
  picture,
  img,
  video {
    position: static;
  }
}

body .mediaLandscape {
  picture,
  img,
  video {
    object-fit: contain;
  }
}

body .mediaHalf {
  width: 50%;
  margin: 0 auto;

  picture,
  img {
    object-fit: contain;
  }
}
