@import '../../../styles/variables.css';

.container {
  padding-top: 40px;
  padding-bottom: 70px;

  @media (--tablet-landscape-up) {
    padding-top: 81px;
    padding-bottom: 164px;
  }
}

.slider {
  :global(.slick-list) {
    max-height: calc(100vh - 175px);

    @media (--tablet-landscape-up) {
      max-height: calc(100vh - 245px);
    }
  }

  :global(.slick-slide) {
    /* opacity: 0; */
    padding: 0 22px;
    /* transition: opacity 0.2s; */
  }

  :global(.slick-active) {
    /* opacity: 1; */
  }

  :global(.slick-arrow) {
    font-size: 0;
  }

  :global(.slick-prev),
  :global(.slick-next) {
    @media (--tablet-landscape-up) {
      @mixin btn-reset;
      position: absolute;
      top: 0;
      width: 50%;
      height: 100%;
      z-index: var(--z-default);
    }
  }

  :global(.slick-prev) {
    left: 0;
    cursor: url('/img/svg/cursor-chevron-back.svg'), auto;
  }
  :global(.slick-next) {
    right: 0;
    cursor: url('/img/svg/cursor-chevron-forward.svg'), auto;
  }

  :global(.slick-dots) {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 14px 0 0 0;

    @media (--tablet-landscape-up) {
      padding-top: 67px;
    }

    li button {
      width: 100%;
      height: 100%;
    }
  }
}

body .media > * {
  position: static;
}
