@import '../../../styles/variables.css';

.container {
  padding: 0 var(--container-padding) 40px;
  color: var(--white);

  @media (--tablet-landscape-up) {
    padding: 114px var(--container-padding);
  }
}

.containerTerms {
  @media (--tablet-portrait-up) {
    display: flex;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @mixin font-family-title;
    @mixin font-bolder;
    font-size: 36px;
    margin-bottom: var(--space-sm);
  }

  p {
    margin-bottom: var(--space-sm);

    @media (--tablet-portrait-up) {
      margin-bottom: var(--space-md);
    }
  }

  ul li {
    position: relative;
    padding-left: 20px;
    font-size: 12px;

    @media (--tablet-portrait-up) {
      font-size: 14px;
    }

    &::before {
      content: '\2022';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.contentTerms {
  @media (--tablet-portrait-up) {
    width: 75%;
    padding-top: 10px;
    padding-right: 20px;
  }

  @media (--tablet-landscape-up) {
    padding-top: 30px;
  }
}

.content {
  max-width: 700px;
  margin: 0 auto;
  color: var(--white);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 27px;
    @mixin font-family-title;
    @mixin font-boldest;
    font-size: 20px;
    line-height: 24px;

    @media (--tablet-landscape-up) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  p,
  a,
  li {
    /* opacity: 0.68; */
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.35px;

    @media (--tablet-landscape-up) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  a {
    @mixin font-bold;
    color: inherit;
  }

  p:not(:last-child) {
    margin-bottom: 32px;
  }
}
