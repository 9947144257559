@import '../../../styles/variables.css';

.container {
  margin-bottom: 25px;
  background-color: #f6f6f6;

  @media (--tablet-landscape-up) {
    margin-bottom: 0;
    pointer-events: none;
  }
}

.image {
  img {
    @mixin fill-image;
  }
}
